import React, { useRef, useState } from 'react'
import { Form, Button, Card, Alert, Container } from "react-bootstrap";
import { useAuth } from '../contexts/AuthContext.js'
import { Link } from 'react-router-dom';

import logoImg from '../assets/logo1.png';

export default function ForgotPassword() {

    const emailRef = useRef()
    const { resetPassword } = useAuth()
    const [error, setError] = useState('')
    const [message, setMessage] = useState('')
    const [loading, setLoading] = useState(false)


    async function handleSubmit(e) {
        e.preventDefault()

       

        try{
            setMessage('')
            setError('')
            setLoading(true)
             await resetPassword(emailRef.current.value)
            setMessage('Chequea tu correo para el siguiente paso')
        } catch {
            setError('Error al resetear la contraseña')
        }

        setLoading(false)
    }

  return (
    <>
 <Container 
    className='d-flex align-items-center justify-content-center'
    style={{ minHeight: "100vh" }}
    >
       <div className="cajita">
    <Card className='bor'>
    <Card.Body>
        <div className="imo">
        <img src={logoImg} alt='' width="120" height="120"/>
        </div>
        <h2 className="text-center mb-4">¿Olvidaste tu contraseña?</h2>
        {error && <Alert variant='danger'>{error}</Alert>}
        {message && <Alert variant="success">{message}</Alert>}
      <Form onSubmit={handleSubmit}>

    
        <Form.Group id="email">
          <Form.Label>Email</Form.Label>
          <Form.Control type="email" ref={emailRef} required />
        </Form.Group>
        <Button disabled={loading} className="w-100 mt-2 btn-dark" type="submit">
              Resetear contraseña
            </Button>
      </Form>
      <div className="w100 text-center mt-4">
        <Link to="/login">Inicio de sesión</Link>
        </div>
    </Card.Body>
  </Card>

    <div className="w100 text-center mt-3">
        ¿Necesitas una cuenta? <Link to="/signup">Regístrate aquí</Link>
    </div>
   </div>
    </Container>
    </>
  )
}