import React, { useState } from 'react';
import { useAuth } from '../contexts/AuthContext.js';
import { db } from '../firebase';
import { Card, Container, Row, Button, Alert } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import logoImg from '../assets/logo1.png';



export default function Home() {

  const { currentUser, logout } = useAuth()
  const navigate = useNavigate()
  const clienteUID = currentUser.uid
  const clienteRef = db.collection('TESTEO-SUIZA').doc(clienteUID);
  const [error, setError] = useState("")


  async function handleLogout(){
    setError('')

    try {
        await logout()
        navigate('/login')
    } catch {
        setError('Error al cerrar sesión')            
    }
}



  return (
  //  <Container className='vh-100 gap-2 w-50 centradoabajo'>
  <>
  <Container className='gap-2 w-50 centradoabajo vh-100'>
    
    <div className="imo mb-0">
        <img src={logoImg} alt='' width="125" height="125"/>
        </div>
<div className="centrado">
        <div className="beta text-center bg-dark">
        <p className="sinmarbo">BETA</p>
        </div>
        </div>
        
    <div className="avercomo">

      <div className="centrado gap-3 mb-3">

    <Link to={'/ideas'} className='carde esca conpale deconone'>
                <div className="centrado">
              <i className="fas fa-lightbulb   fosi2"></i>
                </div>    
                <span className="overlay-text">Ideas</span>
          </Link>

          <Link to={'/guiones'} className='carde esca deconone'>
                <div className="centrado">
              <i className="fas fa-list   fosi2"></i>
                </div>    
                <span className="overlay-text">Guiones</span>

          </Link>

          </div>

          <div className="centrado gap-3">

          <Link to={'/imagenes'} className='carde esca deconone'>
                <div className="centrado">
              <i className="fas fa-camera   fosi2"></i>
                </div>    
                <span className="overlay-text">Imágenes</span>

          </Link>

          <Link to={'/voces'} className='carde esca deconone'>
                <div className="centrado">
              <i className="fas fa-music   fosi2"></i>
                </div>    
                <span className="overlay-text">Voces</span>

          </Link>

          </div>

    </div>

    <div className="biblio centrado">
    <Link to={'/biblioteca'} className='carde esca deconone'>
                <div className="centrado">
              <i className="fas fa-book   fosi3"></i>
                </div>    
                <span className="overlay-text">Biblioteca</span>
          </Link>
          </div>

          <div className="w100 text-center mt-3">
       <Button variant="link" onClick={handleLogout}>Cerrar sesión</Button>
    </div>
   </Container>
   </>
  )
}
