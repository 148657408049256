import { useState, useEffect } from 'react';
import axios from 'axios';

import { db, storage, audioDb } from '../firebase';
import { useAuth } from '../contexts/AuthContext';
import { ref, uploadBytes, getStorage, getDownloadURL } from 'firebase/storage';

import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

import logoImg from '../assets/logo1.png';
import { Link } from 'react-router-dom';

import { Container } from 'react-bootstrap';

import sendBtn from '../assets/send.svg';
import loadingImage from '../assets/loco.svg';

export default function Voces() {
  
    const [input, setInput] = useState("");
    const [audioUrl, setAudioUrl] = useState('');
    const [voiceNum, setVoiceNum] = useState('voz1');

    const text = input;

    const { currentUser } = useAuth()
    const clienteUID = currentUser.uid
    const clienteRef2 = db.collection('TESTEO-SUIZA').doc(clienteUID)


    const [creditoss, setCreditoss] = useState(0); 

    // const [btnVisible, setBtnVisible] = useState(true);

    const [errVisible, setErrVisible] = useState(false);

    //esto me lo ahorro el js si con css pongo el div backgroundcolor transparente y asi solo aparece imagen cuano tal pero el hovr lo jove

    const [showAudio, setShowAudio] = useState(false);

    const [loading, setLoading] = useState(false);
    const [btnVisible, setBtnVisible] = useState(true);



    useEffect(() => {
      const SETEARCRED = async ()  => {
        const clienteDoc2 = await clienteRef2.get();
    
        if (clienteDoc2.exists) {
          const creditos = clienteDoc2.data().CreditosA;
          setCreditoss(creditos)
          console.log('Creditos actuales:', creditos);
          console.log('Creditoss actuales:', creditoss);
        }}
        SETEARCRED();
      },[]

    );

    const guardarRespuestaEnStorage = async (downloadUrl) => {
      try {
        await clienteRef2.collection('AudiosUser').add({
          Fecha: new Date(),
          AudioUrl: downloadUrl, 
          CuentoParaEscuchar: text,
        });

        console.log('Respuesta guardada en Firebase Storage y en Firestore');
      } catch (error) {
        console.error('Error al guardar la respuesta en Firebase Storage o Firestore:', error);
      }
    };


  
    const handleTextToSpeech = async () => {

      setLoading(true);
      setBtnVisible(false);

        try {
          const clienteDoc2 = await clienteRef2.get();

          if (clienteDoc2.exists) {
            const creditos = clienteDoc2.data().CreditosA;
  
            console.log('creditos:', creditos);
  
            setCreditoss(creditos);
  
  
            if (creditos > 0) {





            console.log('inp de handle:', input)
            const response = await axios.get('https://labsai.coquette.agency/text-to-speech', {
                // https://labsai.coquette.agency/text-to-speech
                // ttp://localhost:4000/text-to-speech
                params: { text, voiceNum },
                responseType: 'blob', 
            });
            const blob = new Blob([response.data], { type: 'audio/mpeg' });
            setAudioUrl(URL.createObjectURL(blob));
            console.log('respuesta exitosa')
            setLoading(false);
            setShowAudio(true);

            await clienteRef2.update({
              CreditosA: creditos - 1,
              
              
            });


            //GUARDAR LA URL EN VEZ DEL ARCHIVO, ALIGUAL Q NO GUARDO LAC IMAGEN PA Q NO OCUPE EN FIRESTORE STORAGE DB ENVEZDESTORAGE PQ YA S EALMACENA EN SERVER OPENAI EL AUD  E IMG
            const storage = getStorage();
            const storageRef = ref(storage, `audio/${clienteUID}/${new Date().getTime()}.mp3`);
            await uploadBytes(storageRef, blob);

            const downloadUrl = await getDownloadURL(storageRef);
            await guardarRespuestaEnStorage(downloadUrl);

            console.log("crédito restado")
            setCreditoss(creditos-1);

          } else {
            console.error('El cliente no tiene suficientes créditos para realizar la solicitud.');
            setLoading(false);
            setErrVisible(true);
          }
        } else {
          console.error('El documento del cliente no existe.');
        }
        
        } catch (error) {
            console.error('Error al conectar con el servidor Express o al consultar Firestore:', error);
        }
    };

    const handleEnter = async (e)=> {
        if (e.key === 'Enter') await handleTextToSpeech();
    }

    const handleInputChange = (e) => {
        const value = e.target.value;
        setInput(value);
        // setMessage2(value);
        console.log(input)
        
    };

    return (
     <Container className='vh-100 centrado discol'>
        <Link to='/'   className="imo">
        <img src={logoImg} alt='' width="125" height="125"/>
        {/* <h1>HABITHERO HH</h1> */}
        </Link>
        <div className='main'>
          <div className="centrado">
          {loading &&  <img className='ms-5' src={loadingImage} alt="Cargando..." width={'100px'}/>}
          </div>

{showAudio && ( // Mostrar el div cajaaud si showAudio es true
                <div className="cajaaud centrado">
                    {audioUrl && (
                        <audio controls autoPlay> {/* Aquí agregamos la propiedad autoPlay */}
                            <source src={audioUrl} type="audio/mpeg" />
                            Tu navegador no soporta el elemento de audio.
                        </audio>
                    )}


                </div>
            )}

{errVisible && (
<div className="comof d-flex align-items-center justify-content-center bg-warning p-3 bor">
  <p className=''>NO TIENES SUFICIENTES CRÉDITOS</p>
  <a href='/precios' className='btn-success btn w-100 mt-3 mb-2'>COMPRAR</a>
</div>
      )}

            <div className='chatFooter'>

              <div className="radioos centrado gap-4">
                <div>
                    <label>
                        <input
                            type="radio"
                            value="voz1"
                            checked={voiceNum === 'voz1'}
                            onChange={() => setVoiceNum('voz1')}
                            className='colorblack'
                        />
                        {/* 🤵‍♂️ */}
                    </label>
                </div>

                <div>
                    <label>
                        <input
                            type="radio"
                            value="voz2"
                            checked={voiceNum === 'voz2'}
                            onChange={() => setVoiceNum('voz2')}
                            className='colorblack'
                        />
                        {/* 👰‍♀️ */}
                    </label>
                </div>
                </div>
                {btnVisible && (
                <div className='inp'>
                    <input type='text' placeholder='Envía un mensaje' value={input} onKeyDown={handleEnter} onChange={handleInputChange} maxLength="100"/>
                    {/* LIMITADO MAXLENGTH A 100 PARA AUDIOS DE 5 SEGUNDOS MAXIMO VERSION FREE */}
                    <button className='send' onClick={handleTextToSpeech}><img src={sendBtn} alt='Send'/></button>
                </div>

                )}
               <div className="restante discol">
                <div className="circu2 mt-3">
          <CircularProgressbar
  value={(creditoss / 10) * 100}
  // text={`${((creditoss / 10) * 100).toFixed(1)}%`}
  text={`${creditoss}`}

  styles={buildStyles({
    textColor: '#000000',
    trailColor: '#fff',
    // backgroundColor: '#fff',
    // pathColor: `rgba(111, 222, 333, ${((respuesta2.vasos -1) / 8) * 100})`,
    pathColor: '#000000',
    pathTransitionDuration: 0.5,
})}
/>
</div>
          {/* <p>{creditoss}</p> */}
          </div>
                <p className='text-center'>AIudate no se hace responsable de las voces otorgadas a los usuarios.</p>
            </div>

        </div>
        </Container>
    )
}
