import React, { useState, useEffect } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { getStorage, ref, listAll, getDownloadURL } from 'firebase/storage';
import { getAnalytics, logEvent } from "firebase/analytics";
import { db, analytics } from '../firebase';
import { Link } from 'react-router-dom';
import { Container } from "react-bootstrap";
import logoImg from '../assets/logo1.png';


export default function () {

    const { currentUser } = useAuth();
  const [audioData, setAudioData] = useState([]);
  const [imageData, setImageData] = useState([]);
  const [ideasData, setIdeasData] = useState([]);
  const [guionesData, setGuionesData] = useState([]);


  const currentPath = window.location.pathname;
  const analytics = getAnalytics();

 
  const clienteUID = currentUser.uid
  const clienteRef = db.collection('TESTEO-SUIZA').doc(clienteUID)
//   const clienteRef2 = db.collection('Clientes').doc(clienteUID)


  useEffect(() => {
    if (currentUser) {
      const storageRefPath = `audio/${currentUser.uid}`;
      const storage = getStorage();
      const storageRef = ref(storage, storageRefPath);
      const clienteIdeasRef = clienteRef.collection('IdeasUser');
      const clienteGuionesRef = clienteRef.collection('GuionesUser');


      const clienteRef2 = db.collection('TESTEO-SUIZA').doc(clienteUID).collection('ImagenesURL');


      clienteIdeasRef.get()
      .then((querySnapshot) => {
        const ideasData = [];
        querySnapshot.forEach((doc) => {
          const data = doc.data();
          console.log(data);
          ideasData.push({ ideaText: data.IdeaRes }); // Aquí asumes que la idea se almacena en un campo 'text'
        });
        setIdeasData(ideasData);
      })
      .catch((error) => {
        console.error('Error al obtener ideas:', error);
      });

      clienteGuionesRef.get()
      .then((querySnapshot) => {
        const guionesData = [];
        querySnapshot.forEach((doc) => {
          const data = doc.data();
          console.log(data);
          guionesData.push({ guionText: data.GuionRes }); // Aquí asumes que la idea se almacena en un campo 'text'
        });
        setGuionesData(guionesData);
      })
      .catch((error) => {
        console.error('Error al obtener ideas:', error);
      });


    //   clienteRef2.get()
    //     .then((querySnapshot) => {
    //       const imageData = [];
    //       querySnapshot.forEach((doc) => {
    //         const data = doc.data();
    //         imageData.push({ imageUrl: data.URLdeIMG });
    //       });
    //       setImageData(imageData);
    //     })
    //     .catch((error) => {
    //       console.error('Error al obtener imágenes:', error);
    //     });

    clienteRef2.get()
    .then((querySnapshot) => {
        console.log('seestaejecutando')
      const imageData = [];
      querySnapshot.forEach((doc) => {
        const data = doc.data();
        // Crear un nuevo objeto de imagen
        const imageObj = { imageUrl: data.URLdeIMG, loaded: false };
        // Crear una nueva imagen
        const img = new Image();
        // Manejar el evento de carga de la imagen
        img.onload = () => {
          // Marcar la imagen como cargada
          imageObj.loaded = true;
          // Actualizar el estado solo si la imagen se ha cargado correctamente
          setImageData(prevImageData => [...prevImageData, imageObj]);
        };
        // Manejar el evento de error de la imagen
        img.onerror = () => {
          // No hacer nada o manejar el error según sea necesario
          console.error('Error al cargar la imagen:', data.URLdeIMG);
        };
        // Establecer la URL de la imagen en la nueva imagen
        img.src = data.URLdeIMG;
      });
    })
    .catch((error) => {
      console.error('Error al obtener imágenes:', error);
    });

    
        // clienteRef2.get().then((doc) => {
        //   if (doc.exists) {
        //     const nombre = doc.data().nombre;
        //     const apellidos = doc.data().apellidos;
        //     const fexa =  new Date();
        //     const lola = `Nombre: ${nombre} ${apellidos} | Página: ${currentPath} | Fecha: ${fexa} | UserUID: ${clienteUID}`;
        //     const clien = `Nombre: ${nombre} ${apellidos} | UserUID: ${clienteUID}`
        //     logEvent(analytics, 'page_view', {
        //       COMPLETO: lola, 
        //       CLIENTE: clien,
        //     });
        //     console.log('enteoriasehebviologeevent')
        //   } else {
        //     console.log('El documento del cliente no existe.');
        //   }
        // });
       

      listAll(storageRef)
        .then(async (res) => {
          const audioPromises = res.items.map(async (item) => {
            const audioUrl = await getDownloadURL(item);
            const audioRef = db.collection(`TESTEO-SUIZA/${currentUser.uid}/AudiosUser`).where('AudioUrl', '==', audioUrl);
            const audioQuery = await audioRef.get();
            if (!audioQuery.empty) {
              const audioDoc = audioQuery.docs[0];
              const audioData = audioDoc.data();
              return { audioUrl, cuentoTexto: audioData.CuentoParaEscuchar };
            } else {
              return { audioUrl, cuentoTexto: 'Texto no encontrado' };
            }
          });

          const audioData = await Promise.all(audioPromises);
          setAudioData(audioData);
        })
        .catch((error) => {
          console.error('Error al obtener URLs de descarga:', error);
        });
    }
  }, [currentUser]);


//   const guardarImagen = (url) => {
//     // Crear un elemento de enlace temporal
//     const link = document.createElement('a');
//     link.href = url;
//     link.download = 'imagen.jpg'; // Puedes establecer el nombre del archivo aquí
//     // Simular un clic en el enlace
//     document.body.appendChild(link);
//     link.click();
//     // Eliminar el enlace temporal
//     document.body.removeChild(link);
//   };


  const copiarTextoAlPortapapeles = async (texto) => {
    try {
      await navigator.clipboard.writeText(texto);
      alert('Texto copiado al portapapeles.');
    } catch (error) {
      console.error('Error al copiar al portapapeles:', error);
    }
  };




  return (

    <Container className=' centrado discol'>
        
{/* RECURSOS BIBLIOTECA  */}

<Link to='/'   className="imo mt-3">
        <img src={logoImg} alt='' width="125" height="125"/>
        {/* <h1>HABITHERO HH</h1> */}
        </Link>

<div className="contt mt-1 mb-3      w-100" style={{ maxWidth: '700px' }}>


<div className="main">
    <div className="ideasbox">
<h1 className='text-center'>Ideas</h1>
<div className="d-flex flex-wrap justify-content-center gap-2 conbor">



              {ideasData.length > 0 ? (


                ideasData.map((idea, index) => (

<div className="bg-dark colorwhite p-2 esca centrado text-center gap-1 conbor discol">
                  <div key={index} className="m-3">
                    <p className='sinmarbo'>{idea.ideaText}</p>
                  </div>

                  <div className="d-flex align-items-center justify-content-center">
              <button
                className="btn btn-warning mb-3"
                onClick={() => copiarTextoAlPortapapeles(idea.ideaText)}
              >
                Copiar
              </button>
            </div>

                  </div>

                ))

                
              ) : (
                <div className="text-center mt-4">No hay ideas disponibles.</div>
              )}


            </div>
</div>
</div>





<div className="main">
    <div className="ideasbox">
<h1 className='text-center'>Guiones</h1>
<div className="d-flex flex-wrap justify-content-center gap-2 conbor">



              {guionesData.length > 0 ? (


                guionesData.map((guion, index) => (

<div className="bg-dark colorwhite p-2 esca centrado text-center gap-1 conbor discol">
                  <div key={index} className="m-3">
                    <p className='sinmarbo'>{guion.guionText}</p>
                  </div>

                  <div className="d-flex align-items-center justify-content-center">
              <button
                className="btn btn-warning mb-3"
                onClick={() => copiarTextoAlPortapapeles(guion.guionText)}
              >
                Copiar
              </button>
            </div>

                  </div>

                ))

                
              ) : (
                <div className="text-center mt-4">No hay guiones disponibles.</div>
              )}


            </div>
</div>
</div>

        <div className="main">
        <div className="imagbox">
        <h1 className='text-center'>Imágenes</h1>

{/* //poner tambien textode prompt de audio */}
        <div className="d-flex flex-wrap justify-content-center">
            {imageData.length > 0 ? (
              imageData.map((image, index) => (
                <div key={index} className="m-3">


<div className="cajaima">
                  <img src={image.imageUrl} alt={`Imagen ${index}`} className='laimagen esca'/>
                  {/* <button onClick={() => guardarImagen(image.imageUrl)} className="btn btn-primary mt-2">Guardar</button> */}
                  <a href={image.imageUrl} download={`imagen_${index}.jpg`} className="btn btn-dark mt-2">{' '}Guardar</a>
                  </div>


                </div>
              ))
            ) : (
              <div className="text-center mt-4">No hay imágenes disponibles.</div>
            )}
          </div>


</div>
</div>
   
    <div className='main'>

<div className="audbox">
<h1 className='text-center'>Voces</h1>
  
  {audioData.length > 0 ? (
    audioData.map((audio, index) => (
      <div className="card d-flex justify-content-center align-items-center m-3 bg-dark white p-4 bor esca" key={index}>
        <p className='text-center colorwhite'>{audio.cuentoTexto}</p>
        <div className="d-flex align-items-center justify-content-center mb-3">
              <button
                className="btn btn-warning"
                onClick={() => copiarTextoAlPortapapeles(audio.cuentoTexto)}
              >
                Copiar
              </button>
            </div>
        <div className="d-flex align-items-center justify-content-center">
          <audio controls className='audiete'>
            <source src={audio.audioUrl} type="audio/mpeg" />
            Tu navegador no soporta el elemento de audio.
          </audio>
        </div>
      </div>
    ))
  ) : (
    <div className="text-center mt-4">No hay datos disponibles.</div>
  )}
</div>

    </div>
    </div>

    </Container>


  )
}
