import { useEffect, useRef, useState } from 'react';
import { Card, Container, Row, Button, Alert } from 'react-bootstrap';

import { db, storage, audioDb } from '../firebase';
import { useAuth } from '../contexts/AuthContext';

import { Link } from 'react-router-dom';

import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';


import laotra from '../assets/chefpepe.gif';
import userIcon from '../assets/pepe.gif';
import sendBtn from '../assets/send.svg';
import logoImg from '../assets/logo1.png';


export default function Ideas() {

  const [message, setMessage] = useState('');
  const [resultadoText, setResultadoText] = useState('');
  const instruc = 'Eres un genio de la creación de contenido, el marketing y las redes sociales.  Tienes un amplio conocimiento sobre títulos, miniaturas y contenido que funciona. Carismático con tono humorístico, irónico y sarcástico a veces. Usas emojis de vez en cuando para enriquecer lo que dices. Te llamas AIudate. Has sido creado por un equipo de profesionales creadores de contenido cualificados con un amplio track record.';

  const { currentUser } = useAuth()
  const clienteUID = currentUser.uid
  const clienteRef2 = db.collection('TESTEO-SUIZA').doc(clienteUID)

  const [creditoss, setCreditoss] = useState(0);
  const [errVisible, setErrVisible] = useState(false);


  const msgEnd = useRef(null);

  const [input, setInput] = useState("");
  const [messages, setMessages] = useState([
    {
      // text: 'Hola, soy tu chistera mágica de las ideas. ¿En qué aspecto de tu vida deseas aplicar nuestros principios y encontrar la virtud?',
      isBot: true,
      text: 'Hola, soy tu asesor personal, experto en marketing y redes sociales. 💡 ¿Necesitas alguna idea sobre títulos, miniaturas o contenido?',
    }
  ]);


// meter los 2 useffect en el mismo
  useEffect(() => {
    const SETEARCRED = async ()  => {
      const clienteDoc2 = await clienteRef2.get();

      if (clienteDoc2.exists) {
        const creditos = clienteDoc2.data().CreditosI;
        setCreditoss(creditos)
        console.log('Creditos actuales:', creditos);
        console.log('Creditoss actuales:', creditoss);
      }}
      SETEARCRED();
    },[]

  );


//   estto para q automaticamente new mensaje y scrolee abajo pero tb lo hace al principio
//   useEffect(()=> {
//     msgEnd.current.scrollIntoView();
//   },[messages])


useEffect(() => {
  if (msgEnd.current) {
    msgEnd.current.scrollIntoView({ behavior: 'smooth' });
  }
}, [messages]);


  const handleSend = async () => {
    const text = input;
    setInput('');
    setMessages([
      ...messages,
      { text, isBot: false }
    ]);
    const res = await handleEnviarAOpenAI(text);
    setMessages(prevMessages => [
      ...prevMessages,
      { text: res, isBot: true }
    ]);
    console.log(res);
  }


  const handleEnter = async (e)=> {
    if (e.key === 'Enter') await handleSend();
  }

  const handleQuery = async (e) => {
    const text = e.target.value;
    setMessages([
      ...messages,
      {text, isBot:false}
    ]);
    const res = await handleEnviarAOpenAI(text);
    setMessages([
      ...messages,
      { text, isBot: false },
      { text: res, isBot: true }
    ]);
  }

  // el handlesend funcioen al pulsarboton

  const handleInputChange = (e) => {
    const value = e.target.value;
    setInput(value);
    setMessage(value);
  };

  const handleEnviarAOpenAI = async () => {

    try {

      const clienteDoc2 = await clienteRef2.get();

      if (clienteDoc2.exists) {
        const creditos = clienteDoc2.data().CreditosI;

        console.log('creditos:', creditos);

        setCreditoss(creditos);


        if (creditos > 0) {


      console.log(message);
      const requestData = {
        instructions: instruc,
        message: message,
      };
      console.log('Solicitud a OpenAI:', requestData);
      const response = await fetch('https://textaigpt.coquette.agency/api/openai', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestData),
      });

      if (response.ok) {
        const data = await response.json();
        console.log('Respuesta del servidor Express:', data.response);

        await clienteRef2.update({
          CreditosI: creditos - 1,


        });
        console.log("crédito restado")
        setCreditoss(creditos-1);
        return data.response; // Devuelve el mensaje de respuesta

      } else {
        console.error('Error en la respuesta del servidor Express:', response.status);

      }
    } else {
      console.error('El cliente no tiene suficientes créditos para realizar la solicitud.');
        // setLoading(false);
        setErrVisible(true);
    }
      } else {
        console.error('El documento del cliente no existe.');
      }
    } catch (error) {
      console.error('Error al conectar con el servidor Express o al consultar Firestore:', error);
    }
  };

  const guardarRespuestaFirestore = async (respuesta) => {
    try {
      // await db.collection('respuestas').add({
      //   respuesta: respuesta,
      //   timestamp: new Date(),
      // });


     await clienteRef2.collection('IdeasUser').add({

        Fecha: new Date(),
       IdeaRes: respuesta
      });

      console.log('Respuesta guardada en Firestore.');
      alert('Idea/Respuesta guardada correctamente en la biblioteca.');
    } catch (error) {
      console.error('Error al guardar la respuesta en Firestore:', error);
    }
  };


  return (
    <Container className='centrado discol'  style={{ minHeight: "100vh" }}>
       <Link to='/'   className="imo mt-5">
        <img src={logoImg} alt='' width="125" height="125"/>
        {/* <h1>HABITHERO HH</h1> */}
        </Link>
  <div className="w-100 mt-2 mb-5" style={{ maxWidth: '700px' }}>

      <div className="main">
        <div className='chats' >
        {/* style={{ overflow: 'hidden' }} ESTO PA QUE N OSE VEA SCROLLBAR */}

          {/* ESTE OVERFLOWHIDDEN HACE QUE NO PUEDA USAR SCROLLBAR PQ QUIERO OCULTARLA Y PODER SUBIR Y ALOMEJOR CAMBAIR TIPO WHA LO Q ESCRIBO SALGA A ALA DERECHA Y LO Q RECIBO A LA IZQ */}
          {/* TEXTO EN STREAMING QUE  E SE VAYA AUTOCMPLETANDO COM OEN GPT     O   ANIMACION ESCRIBIENDO EN LOADING SETLOADING  */}
          {/* QUE TENGA EL CONTEXTO DE MENSAJES PREVIOS   TY QUE PIUEDA PREGUNTARLE YMANDALRE IMG INTERPRETE ETC Y EXCEL BIG DAT IA FINETUNE  EMBEDDING  */}
          {messages.map((message, i) =>
            <div key={i} className={message.isBot ? 'chat bot' : 'chat'}>
              <img src={message.isBot ? laotra : userIcon} className='chatImg' alt='' />
              <p className='txt'>{message.text}</p>
              {message.isBot && i !== 0 && (
                <Button onClick={() => guardarRespuestaFirestore(message.text)} className='btn-dark'> <i className="fas fa-save"></i></Button>
              )}
            </div>
          )}
          <div ref={msgEnd} />

          {errVisible && (
<div className="comof d-flex align-items-center justify-content-center bg-warning p-3 bor">
  <p className=''>NO TIENES SUFICIENTES CRÉDITOS</p>
  <a href='/precios' className='btn-success btn w-100 mt-3 mb-2'>COMPRAR</a>
</div>
      )}
        </div>

        <div className='chatFooter'>
          <div className='inp'>
            <input type='text' placeholder='Envía un mensaje' value={input} onKeyDown={handleEnter} onChange={handleInputChange} maxLength="60"/>
            <button className='send' onClick={handleSend}><img src={sendBtn} alt='Send'/></button>
          </div>

              <div className="restante discol">
                <div className="circu2 mt-3">
          <CircularProgressbar
  value={(creditoss / 30) * 100}
  text={`${((creditoss / 30) * 100).toFixed(0)}%`}
  // CAMBIANDO EL TOFIXED A 1 O 2 DA DECIMALES TB PARA CALORIAS EN HABITOS YMAS SI
  styles={buildStyles({
    textColor: '#000000',
    trailColor: '#fff',
    // backgroundColor: '#fff',
    // pathColor: `rgba(111, 222, 333, ${((respuesta2.vasos -1) / 8) * 100})`,
    pathColor: '#000000',
    pathTransitionDuration: 0.5,
})}
/>
</div>
          {/* <p>{creditoss}</p> */}
          </div>

          <p className='text-center'>AIudate no se hace responsable de las respuestas otorgadas a los usuarios.</p>
        </div>
      </div>
      </div>
    </Container>
  );
}
