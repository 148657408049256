import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
// import GoogleAuthProvider from 'firebase/compat/auth';
import 'firebase/compat/firestore';
// import 'firebase/compat/storage';
// import 'firebase/compat/analytics';

// import { getStorage } from 'firebase/storage';

// import { getAnalytics } from "firebase/analytics";

const app = firebase.initializeApp({ 
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGIND_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    // measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
})


const db = firebase.firestore(); 
// const googleprov = new GoogleAuthProvider();

// const storage = firebase.storage();

// const audioDb = getStorage(app);

// const analytics = getAnalytics(app);

export const auth = app.auth();
export { db, app };
// , storage, audioDb, analytics googleprov



