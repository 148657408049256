import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { AuthProvider } from './contexts/AuthContext';

import PublicRoute from './components/routes/PublicRoute';
import PrivateRoute from './components/routes/PrivateRoute';

import Signup from './components/Signup';
import Login from './components/Login';
import ForgotPassword from './components/ForgotPassword';
import Home from './components/Home';
import Ideas from './components/Ideas';
import Imagenes from './components/Imagenes';
import Voces from './components/Voces';
import Guiones from './components/Guiones';
import Biblioteca from './components/Biblioteca';
import Despues from './components/Despues';

function App() {
  return (
<>
<Router>
    <AuthProvider>
      <Routes>
{/* <Route exact path='/' Component={Home}/>
<Route exact path='/ideas' Component={Ideas}/>
<Route exact path='/guiones' Component={Guiones}/>
<Route exact path='/imagenes' Component={Imagenes}/>
<Route exact path='/voces' Component={Voces}/>
<Route exact path='/biblioteca' Component={Home}/>
 */}


<Route exact path='/signup' Component={PublicRoute}>
               <Route exact path='/signup' Component={Signup}/>
            </Route>

            <Route exact path='/login' Component={PublicRoute}>
               <Route exact path='/login' Component={Login}/>
            </Route>

            <Route exact path='/forgot-password' Component={PublicRoute}>
               <Route exact path='/forgot-password' Component={ForgotPassword}/>
            </Route>




            <Route exact path='/F3gH4I5J6K7lM8N9O0P1Q2rS3T4U5' Component={PrivateRoute}>
               <Route exact path='/F3gH4I5J6K7lM8N9O0P1Q2rS3T4U5' Component={Despues}/>
            </Route>



            <Route exact path='/' Component={PrivateRoute}>
               <Route exact path='/' Component={Home}/>
            </Route>

            <Route exact path='/ideas' Component={PrivateRoute}>
               <Route exact path='/ideas' Component={Ideas}/>
            </Route>

            <Route exact path='/guiones' Component={PrivateRoute}>
               <Route exact path='/guiones' Component={Guiones}/>
            </Route>

            <Route exact path='/imagenes' Component={PrivateRoute}>
               <Route exact path='/imagenes' Component={Imagenes}/>
            </Route>

            <Route exact path='/voces' Component={PrivateRoute}>
               <Route exact path='/voces' Component={Voces}/>
            </Route>

            <Route exact path='/biblioteca' Component={PrivateRoute}>
               <Route exact path='/biblioteca' Component={Biblioteca}/>
            </Route>


</Routes>
</AuthProvider>
</Router>
</>
  );
}

export default App;
