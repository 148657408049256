import React, { useState, useEffect } from 'react'
import { db } from '../firebase'
import { useAuth } from '../contexts/AuthContext.js'
import { Form, Button, Card, Alert, Container } from "react-bootstrap";
import { Link, useNavigate } from 'react-router-dom';
import loadingImage from '../assets/loco.svg';
import logoImg from '../assets/logo1.png';

import { useLocation } from 'react-router-dom';

// import { signUpSuccessAppEvent } from '../novisit/facebookPixelEvent';

export default function Despues() {

    const [error, setError] = useState('')
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate()

    const { currentUser } = useAuth()
    const clienteUID = currentUser.uid
    // const clienteRef = db.collection('customers').doc(clienteUID)
    // const clienteRef2 = db.collection('TESTEO-SUIZA').doc(clienteUID)
    const clienteRef2 = db.collection('TESTEO-SUIZA').doc(clienteUID);
    // const clienteRefG = db.collection('TESTEO-SUIZA').doc(clienteUID).collection('CredUser');
    // const [creditoss, setCreditoss] = useState(0); 

    const location = useLocation();
    const nombre = new URLSearchParams(location.search).get('nombre');
    const apelli = new URLSearchParams(location.search).get('apellidos');

    const crearCreditos = async () => {
        const mail = currentUser.email;
        const Nombre = nombre;
        const Apellidos = apelli;
        try {
          // await clienteRefG.set({
            await clienteRef2.set({

            // await clienteRef2.collection('AudiosUser').add({

            nombre: Nombre,
            apellidos: Apellidos,
            email: mail,
            CreditosI: 30,  
            CreditosG: 30,
            CreditosP: 10,
            CreditosA: 15,
          });
          
          console.log('Campo "Creditos" creado en Firestore con valor 0');
          console.log('email', currentUser.email)
          // setCreditoss(0)
        } catch (error) {
          console.error('Error al crear el campo "Creditos" en Firestore:', error);
        }
      };
    




      useEffect(() => {
        crearCreditos().then(() => {
          // signUpSuccessAppEvent();
           navigate('/');
        });
      }, [navigate]);
    

      if (loading) {
        return (

          <Container 
          className='d-flex align-items-center justify-content-center'
          style={{ minHeight: "100vh" }}
          >
           <div className="w-100" style={{ maxWidth: '500px' }}>
            <Card className='d-flex align-items-center justify-content-center bor'>
               <img src={logoImg} alt='' width="130" height="130"/>
            <img className='ms-5 display-4' src={loadingImage} alt="Cargando..."  width="120" height="120" />
          
          </Card>

          </div>
  </Container>
        );
      }

  return (


    <>
     <Container 
    className='d-flex align-items-center justify-content-center'
    style={{ minHeight: "100vh" }}
    >
     <div className="w-100" style={{ maxWidth: '500px' }}>

    <Card>
    <Card.Body>
        <div className="imo">
        <img src={logoImg} alt='' width="40" height="40"/>
        </div>
        <h2 className="text-center mb-4">CREANDO TU PERFIL</h2>
        {error && <Alert variant='danger'>{error}</Alert>}     
    </Card.Body>
  </Card>
  </div>
  </Container>
    </>
  )
}
  

