import { useState, useEffect } from 'react';
import { Container } from 'react-bootstrap'
import sendBtn from '../assets/send.svg';
import loadingImage from '../assets/loco.svg';

import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

import { db, storage, audioDb } from '../firebase';
import { useAuth } from '../contexts/AuthContext';

import logoImg from '../assets/logo1.png';
import { Link } from 'react-router-dom';

import { ref, uploadBytes, getStorage, getDownloadURL } from 'firebase/storage';

export default function Imagenes() {


    const [input, setInput] = useState("");
    const [message2, setMessage2] = useState('');
    const [backgroundImage, setBackgroundImage] = useState(null);

    const { currentUser } = useAuth()
    const clienteUID = currentUser.uid
    const clienteRef2 = db.collection('TESTEO-SUIZA').doc(clienteUID)


    const [creditoss, setCreditoss] = useState(0); 

    const [loading, setLoading] = useState(false);
    const [btnVisible, setBtnVisible] = useState(true);
    const [btnVisible2, setBtnVisible2] = useState(false);
    

    // const [btnVisible, setBtnVisible] = useState(true);

    const [errVisible, setErrVisible] = useState(false);



    useEffect(() => {
        const SETEARCRED = async ()  => {
          const clienteDoc2 = await clienteRef2.get();
      
          if (clienteDoc2.exists) {
            const creditos = clienteDoc2.data().CreditosP;
            setCreditoss(creditos)
            console.log('Creditos actuales:', creditos);
            console.log('Creditoss actuales:', creditoss);
          }}
          SETEARCRED();
        },[]
  
      );




    const handleSubmit2 =  async (e) => {
        e.preventDefault();
        setLoading(true);
        setBtnVisible(false);
        


        try {
            const clienteDoc2 = await clienteRef2.get();
  
            if (clienteDoc2.exists) {
              const creditos = clienteDoc2.data().CreditosP;
    
              console.log('creditos:', creditos);
    
              setCreditoss(creditos);
    
    
              if (creditos > 0) {

                
     const response = await fetch('https://textaigpt.coquette.agency/api/images', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            message2: message2,
            // messageVar: messageVar, 
          }),
        })
        


        
        .then((res) => res.json())
          
        .then((data) => {
          console.log(data)
          setBackgroundImage(data.response);
          // setResponse2(data.response);
          // setBackground(data.response);
          // setShowSaveButton(true);
          setLoading(false);
          setBtnVisible2(true);
          //AQUI UPLOAD A  FIREBASE LA URL DE LA FOTO DEL BLOB Q ES DATA RESPONSE Y LUEGO EN BIBLIOTECA PARA PODER ACCEDER
          clienteRef2.collection('ImagenesURL').add({
              Fecha: new Date(), 
              PromptEnviado: input,
              URLdeIMG: data.response,
              // CuentoParaAmpliar: Respuesta1,
              // PromptEnviado: requestData,
              // CuentoAmpliado: data.response,
              // Sumatorio: `${Respuesta1} ${data.response}`,
          });

          // const storageRef = ref(getStorage(), `images/${clienteUID}/${new Date().getTime()}.jpg`);
          // uploadBytes(storageRef, data.response).then(() => {
          //     console.log("Imagen guardada en Firebase Storage");
          // }).catch((error) => {
          //     console.error("Error al subir imagen a Firebase Storage:", error);
          // });

          clienteRef2.update({
              CreditosP: creditos - 1,
          }).then(() => {
              console.log("Crédito restado");
              setCreditoss(creditos - 1);
          }).catch((error) => {
              console.error("Error al restar crédito:", error);
          });

        });

         


        } else {
            console.error('El cliente no tiene suficientes créditos para realizar la solicitud.');
            setLoading(false);
            setErrVisible(true);
          }
        } else {
          console.error('El documento del cliente no existe.');
        }
        
        } catch (error) {
            console.error('Error al conectar con el servidor Express o al consultar Firestore:', error);
        }
    };





        const handleEnter = async (e)=> {
        if (e.key === 'Enter') await handleSubmit2(e);
      }


    // const handleSend = async () => {
    //     // const text = input;
    //     // setInput('');
    //     // setMessages([
    //     //   ...messages,
    //     //   { text, isBot: false }
    //     // ]);
    //     // const res = await handleEnviarAOpenAI(text);
    //     // setMessages(prevMessages => [
    //     //   ...prevMessages,
    //     //   { text: res, isBot: true }
    //     // ]);
    //     // console.log(res);
    //   }

      
  

      const handleInputChange = (e) => {
        const value = e.target.value;
        setInput(value);
        setMessage2(value);
        console.log(input)
        
      };



  return (
    <Container className='vh-100 centrado discol'>
        <Link to='/'   className="imo">
        <img src={logoImg} alt='' width="125" height="125"/>
        {/* <h1>HABITHERO HH</h1> */}
        </Link>
        <div className="main">
      
        <div className="cajaimg esca" style={{ backgroundImage: `url(${backgroundImage})`, backgroundSize: 'cover' }}>
          
        <div className="centrado ms-5">

          {/* meterle el width en img */}
        {loading &&  <img className='imig' src={loadingImage} alt="Cargando..." />}
        </div>
        </div>
        
        {btnVisible2 && (
        <div className="centrado mb-2">       
            <a href={backgroundImage} download={`${backgroundImage}.jpg`} className="btn btn-dark mt-2">{' '}Guardar</a>
</div>  )}

        {errVisible && (
<div className="comof d-flex align-items-center justify-content-center bg-warning p-3 bor">
  <p className=''>NO TIENES SUFICIENTES CRÉDITOS</p>
  <a href='/precios' className='btn-success btn w-100 mt-3 mb-2'>COMPRAR</a>
</div>
      )}


        <div className='chatFooter'>
        {btnVisible && (
          <div className='inp'>
            <input type='text' placeholder='Envía un mensaje' value={input} onKeyDown={handleEnter} onChange={handleInputChange}  maxLength={'40'}/>
            <button className='send' onClick={handleSubmit2}><img src={sendBtn} alt='Send'/></button>
          </div>
        )}
                <div className="restante discol">
                <div className="circu2 mt-3">
          <CircularProgressbar
  value={(creditoss / 10) * 100}
  // text={`${((creditoss / 10) * 100).toFixed(1)}%`}
  text={`${creditoss}`}

  styles={buildStyles({
    textColor: '#000000',
    trailColor: '#fff',
    // backgroundColor: '#fff',
    // pathColor: `rgba(111, 222, 333, ${((respuesta2.vasos -1) / 8) * 100})`,
    pathColor: '#000000',
    pathTransitionDuration: 0.5,
})}
/>
</div>
          {/* <p>{creditoss}</p> */}
          </div>
          <p className='text-center'>AIudate no se hace responsable de las imágenes otorgadas a los usuarios.</p>
        </div>


        </div>
    </Container>
  )
}
